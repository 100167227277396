/* OweTracker.css */

/* General Styling */
body {
    font-family: 'Roboto', Arial, sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f4f4f4;
    color: #333;
}

.dashboard-container {
    display: flex;
    flex-direction: column;
    padding: 20px;
    margin: 0 auto;
    max-width: 1200px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.search-input {
    width: 100%;
    padding: 8px;
    margin-bottom: 16px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
}

.search-input:focus {
    outline: none;
    border-color: #36A2EB;
    box-shadow: 0 0 0 2px rgba(54, 162, 235, 0.2);
}

/* Header */
h2, h3 {
    font-size: 1.8rem;
    margin-bottom: 10px;
    color: #2c3e50;
    text-align: left;
}

h3 {
    margin-top: 10px;
    font-size: 1.2rem;
    color: #16a085;
}

/* Chart Layout */
.charts-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 10px;
}

.line-chart-container, .bar-chart-container {
    width: 100%;
    min-width: unset;
    max-width: 100%;
    background-color: #f9f9f9;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    margin-bottom: 10px;
}

.bar-chart-container {
    width: 100%;
    min-width: unset;
    max-width: 100%;
    background-color: #f9f9f9;
    padding: 15px;
    padding-bottom: 60px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    margin-bottom: 10px;
    min-height: 500px;
    max-height: 800px;
    height: auto;
    overflow: hidden;
}

/* Add responsive adjustments */
@media (max-width: 768px) {
    .bar-chart-container {
        min-height: 600px;
        max-height: 1000px;
        height: auto;
        padding: 10px;
        padding-bottom: 60px;
        padding-left: 15px;
        padding-right: 15px;
    }

    .month-selector {
        width: 100%;
        margin-bottom: 1.5rem;
        padding: 0.8rem;
        font-size: 1.1rem;
    }

    .bar-chart-container h2 {
        font-size: 1.4rem;
        margin-bottom: 1rem;
    }
}

/* Table Layout */
.scrollable-table {
    max-height: 300px;
    overflow-y: auto;
    margin-top: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #ffffff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.scrollable-table table {
    width: 100%;
    border-collapse: collapse;
}

.scrollable-table th, .scrollable-table td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #ddd;
    font-size: 0.95rem;
}

.scrollable-table th {
    background-color: #16a085;
    color: white;
    font-weight: bold;
    position: sticky;
    top: 0;
    z-index: 1;
}

.scrollable-table td {
    color: #555;
}

.settlement-instruction button {
    background-color: #4CAF50;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 0.9rem;
    transition: background-color 0.3s ease;
}

.settlement-instruction button:hover {
    background-color: #45a049;
}

/* Recent Expenses Section */
.recent-expenses {
    margin-top: 30px;
}

.recent-expenses h2 {
    font-size: 1.8rem;
    color: #34495e;
}

/* Loading Indicator */
.loading {
    font-size: 1.5rem;
    text-align: center;
    color: #777;
    margin: 50px 0;
}

.settle-up-section {
    margin-top: 10px;
    padding: 20px;
    background-color: #fefefe;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

/* Styling for unsettled expenses */
.unsettled {
    background-color: #ffe6e6; /* Light red for unsettled */
    color: #e74c3c; /* Red color for unsettled text */
}

/* Styling for settled expenses */
.settled {
    background-color: #e6ffe6; /* Light green for settled */
    color: #2ecc71; /* Green color for settled text */
}

.monthly-expenses-table {
    margin-top: 20px;
    background-color: white;
    border-radius: 8px;
    padding: 15px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.monthly-expenses-table h3 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    font-size: 1.2rem;
}

.clear-selection {
    background-color: #f0f0f0;
    border: none;
    padding: 5px 10px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.9rem;
    color: #666;
}

.clear-selection:hover {
    background-color: #e0e0e0;
}

.month-selector {
    margin-bottom: 1rem;
    padding: 0.5rem;
    font-size: 1rem;
    border-radius: 4px;
    border: 1px solid #ccc;
}

.delete-button {
    background: none;
    border: none;
    color: #ff4444;
    font-size: 1.2em;
    font-weight: bold;
    cursor: pointer;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    padding: 0;
    line-height: 20px;
    text-align: center;
    display: inline-block;
    margin: auto;
}

.delete-button:hover {
    background-color: rgba(255, 68, 68, 0.1);
}

.scrollable-table td:last-child {
    vertical-align: middle;
    text-align: center;
    padding: 0 12px;
}
