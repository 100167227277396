/* ConfirmModal.css */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-container {
    background-color: #ffffff;
    padding: 2.5rem;
    border-radius: 10px;
    text-align: center;
    width: 90%;
    max-width: 500px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    margin: 1rem;
}

.modal-container h2 {
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
    color: #2c3e50;
    font-family: 'Roboto', Arial, sans-serif;
}

.modal-actions {
    display: flex;
    flex-direction: column-reverse;
    gap: 0.2rem;
    margin-top: 1rem;
}

.modal-actions button {
    width: 100%;
    padding: 12px;
    font-size: 0.95rem;
    font-weight: 600;
    border-radius: 5px;
    transition: background-color 0.3s ease;
    cursor: pointer;
    font-family: 'Roboto', Arial, sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal-actions button:first-child {
    background-color: #16a085;
    color: white;
    border: none;
}

.modal-actions button:first-child:hover {
    background-color: #138a72;
}

.modal-actions button:last-child {
    background-color: #e74c3c;
    color: white;
    border: none;
}

.modal-actions button:last-child:hover {
    background-color: #d44133;
}

@media (min-width: 768px) {
    .modal-actions {
        flex-direction: row;
        justify-content: center;
        gap: 1.5rem;
    }

    .modal-actions button {
        width: auto;
        min-width: 150px;
    }
}