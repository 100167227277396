.csv-upload-container {
    margin: 20px;
  }
  
  .data-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .data-table th, .data-table td {
    border: 1px solid #ccc;
    padding: 8px;
    text-align: left;
  }
  
  .data-table input {
    width: 100%;
    box-sizing: border-box;
  }