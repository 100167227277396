.groups-container {
    display: flex;
    flex-wrap: wrap;
    gap: 25px; /* Space between tiles */
    margin-bottom: 20px;
    justify-content: center; /* Align items to the left */
  }
  
  .group-tile {
    background-color:  #FF6F61; /* Tile color */
    color: white;
    padding: 20px;
    border-radius: 25px;
    cursor: pointer;
    text-align: center;
    width: 250px; /* Fixed width for tiles */
    height: 150px; /* Fixed height for tiles */
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.2s; /* Animation effect */
  }
  
  .group-tile:hover {
    transform: scale(1.05); /* Slightly enlarge on hover */
  }

  .title {
    margin-bottom: '20px';
    color: '#333';
  }
  