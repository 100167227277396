select {
    padding: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
  }

  .config-dropdown-container {
    display: flex;
    align-items: center; /* Aligns items vertically centered */
  }
  
  .config-label {
    margin-right: 10px; /* Adds space between the label and the dropdown */
  }
  
  .config-select {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
  }