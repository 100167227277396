.overall-container {
    background-color: #f9f9f9;
}

.dashboard-container {
    background-color: #f9f9f9;
    min-height: 100vh;
    max-width: 1400px;
    margin: 0 auto;
  }
  
.metrics-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* Changed from 2 to 4 columns */
    gap: 16px;
    margin-bottom: 24px;
  }

.charts-grid {
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 24px;
    margin-bottom: 24px;
  }
  
.finance-insights, 
.expense-overview {
    background: white;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
    display: flex;
    flex-direction: column;
  }
  
  .finance-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .time-range {
    display: flex;
    gap: 8px;
  }
  
  /* Add a wrapper for the chart */
  .chart-wrapper {
    flex: 1;
    position: relative;
    min-height: 400px;
    width: 100%;
    padding: 10px;
  }
  
  .chart-container {
    width: 100%;
    height: 100%;
    position: relative;
  }
  
  /* Add responsive breakpoints */
  @media (max-width: 1024px) {
    .metrics-grid {
      grid-template-columns: repeat(2, 1fr);
    }
    
    .charts-grid {
        display: flex;
        flex-direction: column;
        gap: 24px;
      }
    
    .finance-insights {
      height: 1000px;
    }
    
    .expense-overview {
        width: 100%;
        margin-top: 24px; /* Add some spacing between charts */
      }
    
    .chart-wrapper {
      min-height: 350px;
    }
    .chart-container {
        min-height: 350px;
      }
      
      .expense-overview .chart-wrapper {
        max-height: 400px; /* Limit height on mobile */
      }
  }
  
  @media (max-width: 640px) {
    .metrics-grid {
      grid-template-columns: 1fr;
    }
    
    .finance-header {
      flex-direction: column;
      gap: 12px;
    }
    
    .time-range {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 8px;
      width: 100%;
    }
    
    .time-button {
      color: black;
      width: 100%;
      margin-left: 0 !important;
    }
    
    .budget-item {
      flex-direction: column;
      gap: 4px;
      padding: 12px 0;
      border-bottom: 1px solid #eee;
    }
    
    .integration-list {
      flex-direction: column;
    }
    
    .finance-insights {
      height: auto;
    }
    
    .expense-overview {
      height: 350px;
    }
    
    .chart-wrapper {
      margin-top: 20px;
    }
  }
  
  /* Enhance existing styles */
  .metrics-card {
    background: white;
    padding: 24px 16px;
    border-radius: 12px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
    transition: transform 0.2s;
  }
  
  .metrics-card:hover {
    transform: translateY(-2px);
  }

  .metrics-subtitle {
    font-size: 14px;
    color: gray;
    margin-top: 4px;
  }
  .metrics-card ul {
    list-style: none;
    padding: 0;
    margin: 5px;
    font-size: 14px;
  }
  .metrics-card ul li {
    margin-bottom: 4px;
  }
  
  .metrics-value {
    font-size: 28px;
    font-weight: bold;
    margin: 8px 0;
  }
  
  /* Add height constraints for charts */
  .finance-insights, 
  .expense-overview {
    min-height: 400px;
    height: 100%;
  }
  
  .time-button {
    background: none;
    border: 1px solid #e5e7eb;
    padding: 8px 16px;
    border-radius: 6px;
    margin-left: 8px;
    cursor: pointer;
    transition: all 0.2s;
    color: black;
  }
  
  .time-button:hover {
    background: #f3f4f6;
    border-color: #d1d5db;
    color: black;
  }
  
  .budget-item {
    padding: 12px 0;
    border-bottom: 1px solid #eee;
  }
  
  .budget-item:last-child {
    border-bottom: none;
  }
  
  .integration-item {
    background: #f3f4f6;
    padding: 16px;
    border-radius: 8px;
    text-align: center;
    flex: 1;
    cursor: pointer;
    transition: all 0.2s;
  }
  
  .integration-item:hover {
    background: #e5e7eb;
    transform: translateY(-2px);
  }

  .expense-overview {
    background: white;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
    display: flex;
    flex-direction: column;
    min-height: 400px;
  }

  .expense-overview .chart-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    min-height: 300px;
  }