/* Base styles */
.toolbar-container {
  position: relative;
  z-index: 100; /* Ensure toolbar is above other elements */
}

.hamburger-menu {
  cursor: pointer;
  font-size: 24px;
  padding: 10px;
}

.toolbar {
  position: fixed; /* Fixed position to make it independent of the parent layout */
  top: 0;
  left: -250px; /* Start hidden */
  width: 250px;
  height: 100%;
  background-color: #333; /* Dark background */
  color: #fff;
  display: flex;
  flex-direction: column;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.5);
  transition: left 0.3s ease-in-out;
  z-index: 1000; /* Ensure it is above other components */
}

/* Open state: Slide into view */
.toolbar.open {
  left: 0;
}

.toolbar-header {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
}

.toolbar-close-button {
  font-size: 24px;
  color: #fff; /* Ensure visibility against the dark background */
  background: none;
  border: none;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1001; /* Ensure it is above the toolbar content */
}

.toolbar-items {
  margin-top: 50px; /* Space below the close button */
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
}

.toolbar-items a,
.toolbar-items button {
  color: #fff;
  text-decoration: none;
  font-size: 18px;
  background: none;
  border: none;
  cursor: pointer;
  text-align: left;
}

.toolbar-items a:hover,
.toolbar-items button:hover {
  text-decoration: underline;
}
