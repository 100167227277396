/* Ensure the body and html take up the full height */
body, html {
  height: 100%;
  margin: 0;
  font-family: 'Roboto', Arial, sans-serif;
}

/* The container holding everything in the BulkExpenseUpload component */
.csv-upload-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.25rem;
  gap: 1.25rem;
  min-height: 100vh;
  box-sizing: border-box;
  overflow-x: hidden;
}

.csv-upload-container .toolbar {
  z-index: 1000; /* Inherit high priority from the toolbar styles */
}

.expense-carousel-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%; /* Full width to fit within the container */
  max-width: 800px; /* Optional: Limit max width */
  padding: 1rem;
  box-sizing: border-box;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

/* The Upload New Configuration button */
.upload-button {
  position: relative;
  background-color: #16a085;
  color: white;
  padding: 0.75rem 1.25rem;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-weight: 600;
  margin: 1rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.submit-button {
  background-color: #16a085;
  color: white;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-weight: 600;
  transition: background-color 0.3s;
  margin-top: 1.5rem;
  width: auto;
  min-width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.submit-button:hover:not(:disabled) {
  background-color: #138a72;
}

.submit-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
  opacity: 0.7;
}

/* Style for the Submit Expenses button */
button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

button {
  margin-top: 20px; /* Give it some space from the carousel */
  padding: 10px 20px;
  background-color: #28a745; /* Green for submit */
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #218838;
  color: white;
}

/* Ensure the carousel is centered horizontally */
.csv-upload-container .carousel-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 30px;
  flex-grow: 1; /* Allow the carousel to take up remaining space */
  margin-bottom: 40px; /* Ensure space below the carousel */
}

/* This ensures the ExpenseCarousel itself stays centered in its container */
.csv-upload-container .carousel-container .carousel-item {
  margin: 0 auto;
  display: block; /* Ensure it takes up the full width available */
  width: 100%; /* Make sure the carousel does not overflow */
}

/* Responsive Layout: Ensure good spacing on smaller screens */
@media (max-width: 768px) {
  .csv-upload-container {
    padding: 1rem;
  }

  .csv-upload-container h1 {
    font-size: 1.4rem;
    margin-top: 1rem; /* Space for toolbar */
  }

  .upload-button {
    width: 100%;
    max-width: 300px;
    margin: 0.5rem 0;
    font-size: 0.95rem;
  }

  .expense-carousel-container {
    padding: 0.75rem;
    margin: 0.5rem;
  }

  .submit-button {
    width: 100%;
    max-width: 300px;
    font-size: 0.95rem;
    padding: 0.75rem 1rem;
  }

  /* Adjust carousel spacing */
  .carousel-container {
    margin: 1rem 0;
  }
}

/* Small mobile devices */
@media (max-width: 480px) {
  .csv-upload-container h1 {
    font-size: 1.2rem;
  }

  .expense-carousel-container {
    padding: 0.5rem;
    margin: 0.25rem;
  }

  .upload-button,
  .submit-button {
    font-size: 0.9rem;
    padding: 0.6rem 1rem;
  }
}
