/* Ensure the body takes full height to center everything */
body, html {
    height: 100%;
    margin: 0;
    font-family: Arial, sans-serif; /* Set a modern font */
  }
  
  /* Center the carousel container on the page */
  .carousel-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 20px;
  }
  
  /* Style for the carousel item */
  .carousel-item {
    background-color: #f5f9f9; /* Lighter background color */
    border-radius: 15px;
    box-shadow: 0 6px 30px rgba(0, 0, 0, 0.15); /* More prominent shadow */
    padding: 30px;
    max-width: 600px; /* Set a max-width for responsiveness */
    width: 100%; /* Let it adjust based on the container */
    transition: transform 0.3s ease;
    display: flex;
    flex-direction: column;
    align-items: center; /* Center contents */
    text-align: left; /* Align text to the left for better readability */
    font-size: 1.1em; /* Slightly larger font for better readability */
  }
  
  .carousel-item h2 {
    font-size: 1.8em;
    margin-bottom: 15px;
    color: #333; /* Dark text for the title */
  }
  
  .carousel-item p {
    font-size: 1.1em;
    color: #666;
  }
  
  .carousel-item:hover {
    transform: scale(1.05); /* Subtle scaling effect */
  }

  .carousel-item label select {
    margin-top: 10px;
    padding: 8px;
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1rem;
  }
  
  .carousel-buttons {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 25px;
  }
  
  /* Style buttons */
  button {
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 8px;
    padding: 12px 20px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
    font-size: 1.1em;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  button:hover {
    background-color: #0056b3;
    transform: translateY(-2px); /* Subtle hover effect */
  }
  
  button:active {
    transform: translateY(1px); /* Button press effect */
  }
  
  button:disabled {
    background-color: #ccc; /* Disabled button color */
    cursor: not-allowed;
  }
  
  button[style="color: red"] {
    background-color: #dc3545; /* Red color for delete button */
  }
  
  button[style="color: red"]:hover {
    background-color: #c82333; /* Darker red on hover */
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .carousel-item {
      padding: 20px;
      max-width: 90%; /* Allow more space on smaller screens */
    }
  
    .carousel-buttons {
      flex-direction: column;
      align-items: stretch; /* Stack the buttons vertically */
      gap: 10px;
    }
  
    button {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  