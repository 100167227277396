/* Modal.css */
.modal-overlay {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 2rem;
  border-radius: 12px;
  width: 90%;
  max-width: 1000px;
  max-height: 90vh;
  overflow-y: auto;
  position: relative;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.close-button {
  position: absolute;
  right: 1rem;
  top: 1rem;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #666;
  padding: 0.5rem;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

.file-upload-section {
  margin: 1.5rem 0;
}

.file-input {
  display: none;
}

.file-label {
  display: inline-block;
  padding: 0.8rem 1.5rem;
  background: #f0f0f0;
  border-radius: 6px;
  cursor: pointer;
  transition: background 0.3s;
}

.file-label:hover {
  background: #e0e0e0;
}

.config-container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-top: 1.5rem;
}

.preview-section {
  padding-bottom: 2rem;
  border-bottom: 1px solid #eee;
}

.table-wrapper {
  overflow-x: auto;
}

.preview-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 1rem;
}

.preview-table th,
.preview-table td {
  padding: 0.75rem;
  border-bottom: 1px solid #eee;
  text-align: left;
}

.preview-table th {
  background: #f8f9fa;
  font-weight: 600;
}

.config-section {
  padding-top: 1rem;
}

.config-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5rem;
  margin-top: 1rem;
}

.config-item label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 500;
  color: #333;
}

.config-item select,
.config-item input[type="text"] {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 1rem;
}

.checkbox-item {
  display: flex;
  align-items: center;
}

.checkbox-item label {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.save-button {
  margin-top: 2rem;
  width: 100%;
  padding: 1rem;
  background: #007bff;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 1rem;
  transition: background 0.3s;
}

.save-button:hover {
  background: #0056b3;
}

.no-data {
  text-align: center;
  color: #666;
  margin: 2rem 0;
}

.error-message {
  background: #fff3f3;
  color: #dc3545;
  padding: 1rem;
  border-radius: 6px;
  margin: 1rem 0;
}

/* Add media query for mobile */
@media (max-width: 768px) {
  .modal-content {
    padding: 1.5rem;
  }

  .close-button {
    right: 0.5rem;
    top: 0.5rem;
  }

  h2 {
    padding-right: 40px; /* Make space for close button */
    margin-top: 0.5rem;
  }
}
