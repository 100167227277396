.settings-container {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
}

.recurring-expenses {
    margin-top: 20px;
}

.expense-list {
    list-style: none;
    padding: 0;
}

.expense-item {
    border: 1px solid #ddd;
    margin-bottom: 10px;
    padding: 15px;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.expense-details h3 {
    margin: 0 0 10px 0;
}

.expense-details p {
    margin: 5px 0;
}

.expense-actions button {
    margin-left: 10px;
    padding: 5px 10px;
    border-radius: 4px;
    cursor: pointer;
}